<template>
    <div>
        <div v-if="beingSend" class="mail-loading ">
            <p>Mail is being send.</p>
            <div class="mail-loader"/>
        </div>
        <form v-else id="form-test" v-html="formStruc" class='contact-form' @submit.prevent="onSubmit($event)" method="POST"/>
        <pop-up-msg v-if="pop" @closePop="pop = null" :response="pop"/>
    </div>  
</template>

<script>

    import axios from 'axios';
    import PopUpMsg from './legacy/sub-modules/Notification-Submod.vue';

    export default {
        props:["config"],
        data(){
            return {
                formStruc: "", 
                beingSend: false, 
                pop: null
            } 
        },
        components:{
            PopUpMsg
        },
        watch:{
            'config.id':{
                immediate: true,
                handler(){
                    if(this.config.id){
                        axios.get("https://creation.pronto.lu/mailgen/genform/"+this.config.id).then(
                            ({data}) => {
                                this.formStruc = data;
                                this.$forceUpdate();
                            }
                        ).catch(error => {
                            console.log(error);
                        })
                    }
                }
            }
        },
        methods:{
            onSubmit($event){
                let props = $event.target.elements;
                let sendData = {};
                this.beingSend = true;
                for(let i=0; i < props.length; i++){
                    sendData[props[i].name] = props[i].value;
                }
                axios.post("https://creation.pronto.lu/mailgen/Sendmail", JSON.stringify({fields: sendData, token: this.config.id})).then(({data}) =>
                {
                    this.pop = data;
                    this.beingSend = false;
                }).catch(error => {
                    console.log(error);
                    this.beingSend = false;
                })
            }
        }
    }
</script>
