<template>
    <div class="request-notification-container">
        <div :class="getNotificationClass">
            <p>{{ getNotificationMsg }}</p><span @click="closePop" class="fas fa-times clickable"/>
        </div>
    </div>
</template>

<script>
    import _ from "lodash";

    export default {
        props: ["response"],
        computed:{
            getNotificationClass(){
                if(!this.response.error){
                    return "request-notification request-success";
                }else{
                    return "request-notification request-error";
                }
            },
            getNotificationMsg(){
                return this.response.message;
            }
        },
        methods:{
            closePop(){
                this.$emit("closePop");
            }
        },
        mounted(){
            _.delay(() => {
                this.$emit("closePop");
            }, 5000);
        }
    }

</script>

<style>

    .request-notification-container{
        width: 100%;
        position: fixed;
        bottom: 200px;
        left: 0;
        height: 0;
        z-index: 3
    }

    .request-notification{
        margin: 0 auto;
        width: max-content;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 30px;
    }

    .request-notification p{
        margin: 20px 0;
    }

    .request-notification.request-success{
        background-color: rgba(127, 255, 0, 0.6);
        border: chartreuse solid 2px;
    }

    .request-notification.request-error{
        background-color: rgba(255, 0, 0, 0.6);
        border: red solid 2px;
    }

    .request-notification p{
        padding: 0 20px;
        font-weight: 900;
        font-size: 140%;
    }

    .request-notification span{
        padding-right:20px;
    }
    
</style>
